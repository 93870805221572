<template>
  <div class="videoList">
    <div
      class="videoItem"
      v-for="(item, index) in dataList"
      :key="item.id"
      @touchstart="getTouchStart($event, item)"
      @touchend="getTouchEnd(index)"
      @touchmove="clearTouch"
      @click="goPlay(item, index)"
    >
      <!-- <div>123</div> -->
      <div>
        <div class="videoImgBox">
          <div class="videoMask" v-if="isEdit" @click.stop="">
            <div class="editBtnBox" @click.stop="selectItem(item)">
              <svg-icon class="editBtn" iconClass="videoEditSelected" v-if="item.select"></svg-icon>
              <svg-icon class="editBtn" iconClass="videoEditUnSelected" v-else></svg-icon>
            </div>
          </div>
          <div class="img_box">
            <ImgDecypt class="videoImg" :src="item.cover" ondragstart="return false" />
            <TipIcon :videoInfo="item" />
            <div class="topLabel" v-if="item.publisherTop && $route.path == '/userHomePage'">置顶</div>
            <div class="video_info">
              <div class="watch">
                <svg-icon icon-class="tip_play" />
                <span>{{ item.playCount | watchCount }}次</span>
              </div>
              <div class="time">
                <span>{{ item.playTime | videotime }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="videoDesc">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import TipIcon from '@/components/TipIcon';
import { Toast } from 'vant';
export default {
  name: 'videoList',
  props: {
    dataList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    fromPath: {
      type: String,
    },
    tagId: {
      type: String,
    },
    isEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isTop: {
      type: Boolean,
      default() {
        return false;
      },
    },
    page: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  components: {
    ImgDecypt,
    TipIcon,
  },
  data() {
    return {
      setTop: '',
      isSetTop: false,
      isClear: false,
      pageVideo: 1,
    };
  },
  methods: {
    // 校验是否会员、会员时间是否到期
    checkVip() {
      let uInfo = this.$store.getters.userInfo;
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(uInfo.vipExpireDate).getTime();
      if (uInfo && uInfo.isVip && todayTime < effectiveTime && uInfo.vipLevel > 0) {
        return true;
      }
      return false;
    },
    // 选择批量删除的数据
    selectItem(video) {
      this.dataList.map((item) => (item.id == video.id ? this.$set(item, 'select', !item.select) : ''));
    },
    // 全选
    selectAll(isSelectAll) {
      this.dataList.map((item) => this.$set(item, 'select', isSelectAll));
    },
    // 批量删除
    batchDel() {
      const delArr = this.dataList.map((item) => (item.select ? item.id : undefined)).filter((item) => item);
      if (delArr.length == 0) return Toast('至少选择一项进行删除');
      this.$emit('delItem', delArr);
    },
    getTouchStart(event, item) {
      if (!this.isTop && this.isClear) {
        this.isClear = false;
        clearTimeout(this.setTop);
        return;
      }
      // event.preventDefault();
      clearTimeout(this.setTop);
      this.setTop = setTimeout(() => {
        let data = {
          id: item.id,
          action: item.publisherTop ? 1 : 2,
          popShow: true,
          type: 'SP',
          promoteStatus: item.isPopping ? 1 : 2,
          status: item.status || 0,
          publisherPopStatus: item.publisherPopStatus || false,
          promoteInfo: {
            cover: item.cover,
            title: item.title,
            user: item.publisher,
          },
        };
        if (!this.$route.query.uid) {
          this.isSetTop = true;
          // if (!this.checkVip()) {
          //   Toast('抱歉，只有会员才可以置顶')
          //   return;
          // }
          this.$store.commit('user/UPDATE_SETTOPDATA', data);
        }
      }, 600);
    },
    getTouchEnd(index) {
      if (!this.isTop && this.isClear) {
        this.isClear = false;
        clearTimeout(this.setTop);
        return;
      }
      clearTimeout(this.setTop);
      if (this.isSetTop) {
        this.isSetTop = false;
      } else {
        // this.goPlay(index);
      }
      // event.preventDefault();
    },
    clearTouch() {
      this.isClear = true;
      this.isSetTop = false;
      clearTimeout(this.setTop);
    },
    del(item) {
      this.$emit('delItem', item);
    },
    goPlay(item, index) {
      this.$router.push({
        path: '/communityVideo',
        query: {
          fromPath: this.fromPath,
          page: Math.ceil(index / 10) || 1,
          vid: item.id,
          uid: item.publisher.uid,
          fromRoutePath: this.$route.name,
          tagId: this.tagId || '',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.videoList {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  grid-gap: 14px;

  .videoItem {
    overflow: hidden;

    .videoImgBox {
      height: 271px;
      position: relative;

      .videoMask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;
        border-radius: 6px;

        .editBtnBox {
          width: 22px;
          height: 22px;
          .editBtn {
            width: 22px;
            height: 22px;
            position: absolute;
            top: 15px;
            right: 10px;
          }
        }
      }

      .img_box {
        position: relative;
        height: 271px;
        border-radius: 4px;
        overflow: hidden;
        z-index: 1;
        .videoImg {
          width: 100%;
          height: 271px;
          border-radius: 2px;
          overflow: hidden;
        }

        .video_icon {
          position: absolute;
          top: 0;
          font-size: 10px;

          .v_gold {
            // width: 40px;
            // height: 18px;
            padding: 2px 8px;
            line-height: 20px;
            text-align: center;
            border-radius: 4px 0 4px 0;
            background: linear-gradient(#fe765b, #ff455b);

            svg {
              padding-right: 4px;
            }
          }

          .v_vip {
            width: 40px;
            height: 18px;
            line-height: 20px;
            text-align: center;
            border-top-left-radius: 4px;
            background: linear-gradient(#ff7426, #e77c2e);
          }
        }

        .topLabel {
          width: 43px;
          height: 21px;
          line-height: 21px;
          border-radius: 30px;
          text-align: center;
          color: #fff;
          background: linear-gradient(to right, #f8b427, #e38825);
          position: absolute;
          top: 6px;
          right: 8px;
          font-size: 12px;
        }

        .video_info {
          width: 100%;
          box-sizing: border-box;
          position: absolute;
          bottom: 0;
          height: 24px;
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          padding: 10px;
          color: #dcdee0;
          font-weight: 500;
          border-radius: 6px;
          overflow: hidden;
          .watch {
            display: flex;
            justify-content: center;
            align-items: center;
            > svg {
              padding-right: 5px;
              height: 14px;
              width: 14px;
            }
          }
        }
      }
    }

    .videoDesc {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 8px;
      font-size: 14px;
    }
  }
}
</style>
